var PaymentStatusEnum;
(function (PaymentStatusEnum) {
    PaymentStatusEnum["Paid"] = "PAID";
    PaymentStatusEnum["UnPaid"] = "UNPAID";
    PaymentStatusEnum["InProgress"] = "IN_PROGRESS";
})(PaymentStatusEnum || (PaymentStatusEnum = {}));
var SubscriptionPlan;
(function (SubscriptionPlan) {
    SubscriptionPlan["Starter"] = "Starter";
    SubscriptionPlan["Advanced"] = "Advanced";
    SubscriptionPlan["Pro"] = "Pro";
})(SubscriptionPlan || (SubscriptionPlan = {}));
export { PaymentStatusEnum, SubscriptionPlan };
