import { jsx as _jsx } from "react/jsx-runtime";
import AvailabilityBadge from '../AvailabiltyBadge';
export const renderValue = (value) => (typeof value === 'boolean' ?
    _jsx(AvailabilityBadge, { isAvailable: value }) :
    value);
export const defineInfoCellStyles = ({ theme, isFirstElement, isPlanDescription, }) => {
    if (isFirstElement && isPlanDescription) {
        return {
            padding: `${theme.spacing_sizes.xs * 1.25}px 0`,
        };
    }
    if (isPlanDescription) {
        return {
            padding: `${theme.spacing_sizes.xs * 1.5}px 0`,
            lineHeight: '24px',
        };
    }
    if (isFirstElement) {
        return {
            padding: `${theme.spacing_sizes.xm}px 0`,
            lineHeight: '18px',
        };
    }
    return {
        padding: `${theme.spacing_sizes.xs * 1.75}px 0`,
        lineHeight: '20px',
    };
};
export const checkPlanFee = (plan) => (plan && typeof plan.fee === 'number' ?
    plan.fee : undefined);
